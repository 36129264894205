import { Component, OnInit } from '@angular/core';
import { TalentService } from '../talent.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css']
})
export class UserSettingsComponent implements OnInit {

  settings: any;
  signOutSuccess : boolean = false
  signOutError: Error = null
  constructor(private talentService: TalentService) { }

  ngOnInit() {
    this.getUserSettings();
  }
  getUserSettings(): void{
    this.talentService.getUserSettings()
    .subscribe(result=>{
      this.settings = result;
    });
  }
  updateUserSettings(): void{
    this.talentService.updateUserSettings(this.settings)
    .subscribe(result=>{
      console.log(result)
    });
  }

  signOutOfGoogle(): void{
    console.log('logging out of google')
    this.talentService.googleSignOut().then(()=>{
      this.signOutSuccess = true
    }).catch(e => {
      this.signOutError = e
      this.signOutSuccess = false
    })
  }

}
