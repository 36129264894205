import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { TalentService } from '../talent.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router,private talentService: TalentService) { }

  ngOnInit() {
    localStorage.clear();
    localStorage.removeItem('isLoggedIn');

    this.talentService.googleSignOut()
  }

}
