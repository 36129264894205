import { Inject, Optional, Component, OnInit } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material";
import { TalentService } from "../talent.service";
import { AiSummary } from "../classes/AiSummary";
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-workflow-ai-summary',
  templateUrl: './workflow-ai-summary.component.html',
  styleUrls: ['./workflow-ai-summary.component.css']
})

export class WorkflowAiSummaryComponent implements OnInit {

  wf_id: number;
  summary: AiSummary;
  loadingWorkflow: boolean = false
  noWorkflow: boolean = false

  constructor(
    @Optional() private dialogRef: MatDialogRef<WorkflowAiSummaryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) data,
    private talentService: TalentService
  ) {
    console.log(data);
    this.wf_id = data.wf_id;
  }

  ngOnInit() {
    this.getWorkflow()
  }

  workflowTimedOut(): boolean{
    console.log('test', this.summary.process_start_time, typeof this.summary.process_start_time)

    const isOlderThan60Mins = new Date(this.summary.process_start_time).getTime() + (60 * 60 * 1000) < Date.now();
    return !this.summary || (!this.summary.done_processing && isOlderThan60Mins)
  }

  getWorkflow() {
    this.loadingWorkflow = true;
    this.talentService.getWorkflowSummary(this.wf_id)
      .pipe(
        catchError((error) => {
          // Check for 404 error
          if (error.status === 404) {
            console.error('Workflow not found (404).');
            this.summary = null; // Handle 404 case, e.g., reset summary or show a message
            this.noWorkflow = true; //no workflow exists
          } else {
            console.error('An error occurred:', error.message);
          }
          this.loadingWorkflow = false; // Ensure loading spinner stops
          return of(null); // Return a safe fallback or empty observable
        })
      )
      .subscribe((result) => {
        if (result) {
          console.log(result);
          this.summary = result;
          this.noWorkflow = false
        }
        this.loadingWorkflow = false; // Ensure spinner stops
      });
  }


  processWorkflow(): void {
    this.loadingWorkflow = true;
    this.talentService.processWorkflow(this.wf_id).subscribe({
      next: (result) => {
        console.log(result);
        this.getWorkflow();
      },
      error: (error) => {
        this.loadingWorkflow = false;
        // Handle the error here - maybe show a message to the user
        console.error('An error occurred:', error);
        //error has a field error which contains body of the error
        //backend returns response of form {error: message for backend}
        //so that's why we have this
        window.alert(`unable to generate ai summary.  Error: \n ${error.error.error}`)
        // You might want to show this in your UI
      },
      complete: () => {
        this.loadingWorkflow = false;
      }
    });
  }

  getScoreColor(score: number): string {
    if (score >= 80) return '#28a745'; // green
    if (score >= 60) return '#ffc107'; // blue
    return '#dc3545'; // red
  }
}
