import { TalentService } from "../talent.service";
import {
  Optional,
  Inject,
  Input,
  Component,
  OnInit,
  ViewEncapsulation,
  SimpleChanges,
  SimpleChange,
} from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material";
import { RoleDetailEditComponent } from "../role-detail-edit/role-detail-edit.component";
import { formatDate } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import * as XLSX from "xlsx";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import {PipelineOption} from '../classes/RolePipeline'
//Uncomment getOpenRoles() function

//{openRolesContext:'openRoles',openRolesContextId:0}
//{provide:MAT_DIALOG_DATA , useValue: {}}
// providers: [MatDialog,
//  {provide: MatDialogRef, useValue: {}}]
@Component({
  selector: "app-open-roles",
  templateUrl: "./open-roles.component.html",
  styleUrls: ["./open-roles.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class OpenRolesComponent implements OnInit {
  @Input() client_roles: any;
  @Input() filter: string;
  @Input("start_date") start_date: number;
  @Input("end_date") end_date: number;
  contexData: any;
  sortType: string = "open_date";
  sortReverse: boolean = false;
  open_only = true;
  show_checkbox = true;
  user_drop_down_data = [];
  role_owner;
  changeOwner = false;
  changeOwnerSave = false;
  showChangeRoleOwner = 0;
  showAddCandidateToRoleBtn = 0;
  isEditable = true;
  showEdit = true;
  showSave = false;
  showEditRecruiter = true;
  showSaveRecruiter = false;
  showEditSourcer = true;
  showSaveSourcer = false;
  showEditPriority = true;
  showSavePriority = false;
  showEditPipeline = true;
  showSavePipeline = false;
  showEditManager = true;
  showSaveManager = false;
  showEditWorkPermission = true;
  showSaveWorkPermission = false;
  showEditPracticeGroup = true;
  showSavePracticeGroup = false;
  workPermissionOptions: any;
  priority_options: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  saveChangesDict: any = [];
  saveChangesDictSourcer: any = {};
  saveChangesDictPriority: any = [];
  saveChangesDictPipleine: any = [];
  saveChangesDictRecruiter: any = {};
  saveChangesDictManager: any = [];
  saveChangesWorkPermission: any = {};
  saveChangesDictPracticeGroup: any=[];
  show_detail: Object = {};
  showingSimilarRoles: any = false;
  role_id;
  sourcer_options: any = [];
  user_name_by_id: Object = {};
  work_permission_name_by_id: Object = {};
  roles: any = [];
  allRoles: any = [];
  count: Object = {};
  clients: Array<any> = [];
  clients_lookup: Object = {};
  base_url: String = "";
  fileName = "Open_Roles_Table.xlsx";
  pagination_id = -1;
  practice_group_options: any = [];
  pipelineOptions: PipelineOption[] = []
  modelOptions: string[] = []

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    @Optional() private dialogRef: MatDialogRef<OpenRolesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) data,
    private talentService: TalentService
  ) {
    if (data == null) {
      this.contexData = {
        openRolesContext: "openRoles",
        openRolesContextId: 0,
      };
    } else {
      this.contexData = data;
      this.show_checkbox = false;
    }
  }

  ngOnInit() {
    if (this.client_roles && this.client_roles.length > 0) {
      this.pagination_id = this.client_roles[0].role_id;
    }
    this.base_url = location.origin;
    this.talentService.getYodaweUsersData().subscribe((yodawe_users) => {
      this.user_drop_down_data = yodawe_users;
      let user_info = this.user_drop_down_data.find(
        (x) => x.id === this.talentService.userId
      );
      if (user_info && user_info.edit_role_ownership_access) {
        this.showChangeRoleOwner = 1;
      }
    });

    // this.talentService.getSourcer()
    // .subscribe(result=>{
    //   this.sourcer_options = result;
    //   console.log('sourcer options',this.sourcer_options)
    // });
    this.talentService.getRolePracticeGroups().subscribe((result) => {
      this.practice_group_options = result;
    });

    this.talentService.getYodaweUsers().subscribe((result) => {
      this.sourcer_options = result;
      for (let r of result) {
        this.user_name_by_id[r["id"]] = r["name"];
      }
    });

    this.talentService.getDisplayYesNo().subscribe((result) => {
      this.workPermissionOptions = result;
      for (let wp_info of this.workPermissionOptions) {
        this.work_permission_name_by_id[wp_info["id"]] = wp_info["name"];
      }
    });

    this.talentService.getYodaweClients().subscribe((yodawe_client) => {
      this.clients = yodawe_client;
      for (let c of this.clients) {
        this.clients_lookup[c["company_name"]] = c["id"];
      }
    });

    this.talentService.getPipelineColours().subscribe((result) => {
      this.pipelineOptions = result as PipelineOption[];
      console.log('pipeline options open', this.pipelineOptions)
    })

    this.role_id = this.activatedRoute.snapshot.paramMap.get("id");
    if (
      !this.router.url.includes("client") &&
      !this.router.url.includes("contact") &&
      this.role_id
    ) {
      this.open_only = false;
      this.filter = this.role_id.toString();
    }

    if (this.contexData.openRolesContext == "addToRole") {
      this.showAddCandidateToRoleBtn = 1;
      this.showEdit = false;
      this.talentService
        .getCandidateWorkflow(this.contexData.openRolesContextId)
        .subscribe((result) => {
          result.forEach((n) => (this.isDisabled[n.role_id] = true));
        });
    }
    if (this.client_roles) {
      this.filter = "";
      this.open_only = true;
      this.allRoles = this.client_roles;
      if (this.open_only) {
        this.roles = this.client_roles.filter(function (role) {
          return role.status == 1 || role.status == 3;
        });
      } else {
        this.roles = this.client_roles;
      }
    } else {
      if (this.role_id) {
        this.getOpenRoles("all");
        this.show_detail[this.role_id] = true;
      } else {
        this.getOpenRoles("open");
      }
    }
  }

  ngOnchanges(changes: SimpleChange) {
    if (this.open_only) {
      this.allRoles = this.client_roles;
      this.roles = this.client_roles.filter(function (role) {
        return role.status == 1 || role.status == 3;
      });
    } else {
      this.allRoles = this.client_roles;
      this.roles = this.client_roles;
    }
  }

  findRecruiter(role) {
    let found = this.sourcer_options.find((option) => {
      return option.id === role.recruiter_id;
    });
    if (found) {
      return found.name;
    } else {
      return "";
    }
  }
  findSourcer(role) {
    let found = this.sourcer_options.find((option) => {
      return option.id === role.sourcer_id;
    });
    if (found) {
      return found.name;
    } else {
      return "";
    }
  }
  findManager(role) {
    let found = this.sourcer_options.find((option) => {
      return option.id === role.account_manager;
    });
    if (found) {
      return found.name;
    } else {
      return "";
    }
  }

  getOpenRoles(open_or_all): void {
    this.talentService.getOpenRolesNew(open_or_all).subscribe((roles) => {
      this.allRoles = roles;
      this.roles = roles;
    });
    // this.roles = TempRoles
    // this.allRoles = TempRoles
  }

  isDisabled = [];

  Checkbox() {
    if (this.client_roles) {
      this.filter = "";
      this.allRoles = this.client_roles;
      console.log(this.client_roles);
      console.log(this.open_only);
      if (this.open_only) {
        this.roles = this.client_roles.filter(function (role) {
          return role.status == 1 || role.status == 3;
        });
      } else {
        this.roles = this.client_roles;
      }
    } else {
      if (this.open_only) {
        this.getOpenRoles("open");
      } else {
        this.getOpenRoles("all");
      }
    }
  }
  rowClick(role_id): void {
    var role_detail;
    this.isDisabled[role_id] = true;
    for (var i = 0; i < this.roles.length; i++) {
      if (role_id == this.roles[i].role_id) {
        role_detail = this.roles[i];
      }
    }
    console.log("role_id clicked: " + role_id);

    if (this.contexData.openRolesContext == "addToRole") {
      var x = {
        candidate_id: this.contexData.openRolesContextId,
        role_details: role_detail,
      };

      this.talentService.addCandidateToRole(x).subscribe((result) => {
        this.talentService.getSimilarRoles(role_id).subscribe((result) => {
          if (result.length > 0 && !this.showingSimilarRoles) {
            let similarRoleIds = result.map((n) => n.role_id);
            this.roles = this.allRoles.filter((n) =>
              similarRoleIds.includes(n.role_id)
            );
            let role = this.allRoles.find((n) => n.role_id === role_id);
            this.showingSimilarRoles = role.client + " - " + role.title;
          }
        });
        if (result.status == "success") {
          alert(
            "Add Candidate to the Role: " +
              role_detail["client"] +
              " successfully"
          );
        } else {
          alert("Add failed, please connect with support");
        }
      });
    } else if (this.contexData.openRolesContext == "openRoles") {
      this.talentService.addRoleToUser(role_id).subscribe((result) => {
        console.log(result);
      });
    }
  }
  detail_toggle(id): void {
    this.show_detail[id] = !this.show_detail[id];
    if (this.show_detail[id]) {
      //
    }
  }

  clickEdit() {
    if (this.showChangeRoleOwner == 1) {
      this.showEdit = false;
      this.showSave = true;
      this.isEditable = false;
    }
  }

  clickEditSourcer() {
    if (this.isEditable) {
      this.showEditSourcer = false;
      this.showSaveSourcer = true;
      this.isEditable = false;
    } else {
      console.log("");
    }
  }

  clickEditRecruiter() {
    if (this.isEditable) {
      this.showEditRecruiter = false;
      this.showSaveRecruiter = true;
      this.isEditable = false;
    } else {
      console.log("");
    }
  }

  clickEditPriority() {
    if (this.isEditable) {
      this.showEditPriority = false;
      this.showSavePriority = true;
      this.isEditable = false;
    } else {
      console.log("");
    }
  }

  clickEditPipeline() {
    if (this.isEditable) {
      this.showEditPipeline = false;
      this.showSavePipeline = true;
      this.isEditable = false;
    } else {
      console.log("");
    }
  }

  clickEditManager() {
    if (this.isEditable) {
      this.showEditManager = false;
      this.showSaveManager = true;
      this.isEditable = false;
    } else {
      console.log("");
    }
  }

  clickEditWorkPermission() {
    if (this.isEditable) {
      this.showEditWorkPermission = false;
      this.showSaveWorkPermission = true;
      this.isEditable = false;
    } else {
      console.log("");
    }
  }

  clickEditPracticeGroup() {
    if (this.isEditable) {
      this.showEditPracticeGroup = false;
      this.showSavePracticeGroup = true;
      this.isEditable = false;
    } else {
      console.log("");
    }
  }
  clickCancelEditPracticeGroup() {
      this.showEditPracticeGroup = true;
      this.showSavePracticeGroup = false;
      this.isEditable = true;
  }

  createRole() {
    const dialogRef = this.dialog.open(RoleDetailEditComponent, {
      width: "1000px",
      height: "500px",
      data: {
        initialDetails: {
          status: 1,
          type: 1,
          category: 1,
          compensation_currency: 1,
          open_compensation: 0,
          sponsorship: 5,
          location_state: 1,
          open_date: formatDate(new Date(), "yyyy-MM-dd", "en"),
          contract_compensation: 0,
          contract_compensation_daily: 0,
          bill_rate: 0,
          bill_rate_daily: 0,
          bill_rate_currency: 1,
          on_hold_notes: "",
          priority: 10,
          sourcers: [],
          recruiters: [],
          work_permission: [],
        },
        create_new: true,
      },
    });
    const sub = dialogRef.componentInstance.doneWithSave.subscribe((save) => {
      dialogRef.close();
      if (save) {
        this.Checkbox();
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }
  cloneRole = (inputRole) => {
    inputRole["title"] = inputRole["title"].split(" - Req. Number")[0];
    const dialogRef = this.dialog.open(RoleDetailEditComponent, {
      width: "1000px",
      height: "500px",
      data: {
        initialDetails: inputRole,
        create_new: true,
      },
    });
    const sub = dialogRef.componentInstance.doneWithSave.subscribe((save) => {
      dialogRef.close();
      if (save) {
        this.Checkbox();
      }
    });
  };

  save() {
    this.showSave = false;
    this.showEdit = true;
    console.log(this.saveChangesDict);
    this.talentService.saveRoleOwners(this.saveChangesDict).subscribe(
      () => {},
      (error) => {
        window.alert("Error saving role owners");
      },
      () => {
        console.log("successful");
        this.saveChangesDict = [];
        if (this.open_only) {
          this.getOpenRoles("open");
        } else {
          this.getOpenRoles("all");
        }
      }
    );
  }

  saveRecruiter() {
    this.isEditable = true;
    this.showSaveRecruiter = false;
    this.showEditRecruiter = true;
    this.talentService
      .saveRoleRecruiter(this.saveChangesDictRecruiter)
      .subscribe(
        () => {},
        (error) => {
          window.alert("Error saving Recruiter");
        },
        () => {
          console.log("successful");
          this.saveChangesDictRecruiter = {};
          if (this.open_only) {
            this.getOpenRoles("open");
          } else {
            this.getOpenRoles("all");
          }
        }
      );
  }

  saveSourcer() {
    this.isEditable = true;
    this.showSaveSourcer = false;
    this.showEditSourcer = true;
    this.talentService.saveRoleSourcer(this.saveChangesDictSourcer).subscribe(
      () => {},
      (error) => {
        window.alert("Error saving sourcer");
      },
      () => {
        console.log("successful");
        this.saveChangesDictSourcer = {};
        if (this.open_only) {
          this.getOpenRoles("open");
        } else {
          this.getOpenRoles("all");
        }
      }
    );
  }
  savePriority() {
    this.showSavePriority = false;
    this.showEditPriority = true;
    this.isEditable = true;
    this.talentService.saveRolePriority(this.saveChangesDictPriority).subscribe(
      () => {},
      (error) => {
        window.alert("Error saving Priority");
      },
      () => {
        console.log("successful");
        this.saveChangesDictPriority = [];
        if (this.open_only) {
          this.getOpenRoles("open");
        } else {
          this.getOpenRoles("all");
        }
      }
    );
  }

  savePipeline() {
    this.showSavePipeline = false;
    this.showEditPipeline= true;
    this.isEditable = true;
    console.log('dict', this.saveChangesDictPipleine)
    this.talentService.saveRolePipeline(this.saveChangesDictPipleine).subscribe(
      () => {},
      (error) => {
        window.alert("Error saving Pipeline");
      },
      () => {
        console.log("successful");
        this.saveChangesDictPipleine = [];
        if (this.open_only) {
          this.getOpenRoles("open");
        } else {
          this.getOpenRoles("all");
        }
      }
    );
  }

  saveManager() {
    this.showSaveManager = false;
    this.showEditManager = true;
    this.isEditable = true;
    this.talentService.saveRoleManager(this.saveChangesDictManager).subscribe(
      () => {},
      (error) => {
        window.alert("Error saving Recruiter");
      },
      () => {
        console.log("successful");
        this.saveChangesDictManager = [];
        if (this.open_only) {
          this.getOpenRoles("open");
        } else {
          this.getOpenRoles("all");
        }
      }
    );
  }

  saveWorkPermission() {
    this.isEditable = true;
    this.showSaveWorkPermission = false;
    this.showEditWorkPermission = true;
    this.talentService
      .saveWorkPermission(this.saveChangesWorkPermission)
      .subscribe(
        () => {},
        (error) => {
          window.alert("Error saving sourcer");
        },
        () => {
          console.log("successful");
          this.saveChangesWorkPermission = {};
          if (this.open_only) {
            this.getOpenRoles("open");
          } else {
            this.getOpenRoles("all");
          }
        }
      );
  }
  savePracticeGroup() {
    this.isEditable = true;
    this.showSavePracticeGroup = false;
    this.showEditPracticeGroup = true;
    this.talentService.saveRolePracticeGroup(this.saveChangesDictPracticeGroup).subscribe(
      () => {},
      (error) => {
        window.alert("Error saving Practice Group");
      },
      () => {
        console.log("successful");
        this.saveChangesDictPracticeGroup = {};
        if (this.open_only) {
          this.getOpenRoles("open");
        } else {
          this.getOpenRoles("all");
        }
      }
    );
  }

  backToAllRoles() {
    this.roles = this.allRoles;
    this.showingSimilarRoles = false;
  }

  onChange(newValue, role_id) {
    this.saveChangesDict.push([parseInt(newValue), role_id]);
  }

  onChangeSourcer(newValue, role_id) {
    this.saveChangesDictSourcer[role_id] = newValue;
  }
  onChangePriority(newValue, role_id) {
    this.saveChangesDictPriority.push([newValue, role_id]);
    console.log("Priority", this.saveChangesDictPriority);
  }
  onChangePipeline(newId:number, role_id:number) {
    this.saveChangesDictPipleine.push([Number(newId), role_id]);
    console.log("Pipeline", this.saveChangesDictPipleine);
  }
  onChangeRecruiter(newValue, role_id) {
    this.saveChangesDictRecruiter[role_id] = newValue;
    console.log("Recruiter", this.saveChangesDictRecruiter);
  }
  onChangeManager(newValue, role_id) {
    this.saveChangesDictManager.push([newValue, role_id]);
    console.log("Recruiter", this.saveChangesDictManager);
  }

  onChangeWorkPermission(newValue, role_id) {
    this.saveChangesWorkPermission[role_id] = newValue;
  }
  onChangePracticeGroup(newValue, role_id) {
    this.saveChangesDictPracticeGroup.push({role_id:role_id,newGroupId:newValue});
  }

  openClientTab(company_name) {
    let url = this.base_url + "/client/" + this.clients_lookup[company_name];
    window.open(url, "_blank");
  }
  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }


  getPriorityColor(priority: number): string {
    if(priority == 1){
      return "#ffffff"
    }
    else if (priority >= 2 && priority <= 3) {
      return '#ffe3e3'; // Low priority
    } else if (priority >= 4 && priority <= 7) {
      return '#fbffe1'; // Medium priority
    } else if (priority >= 8 && priority <= 10) {
      return '#e1ffe6'; ; // High priority
    }
    return 'transparent'; // Fallback (no color)
  }

  getPipelineColour(targetId: any) : string {
    const matchingOption = this.pipelineOptions.find(option => option.id == targetId);
    if(matchingOption)
      return matchingOption.colour
    return "#ffffff"
  }
}
