import { Component, OnInit, Input, Output, EventEmitter,Optional,Inject } from "@angular/core";
import { TalentService } from "../talent.service";
import { saveAs } from "file-saver";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { RoleTemplateGeneratorComponent } from "./role-template-generator/role-template-generator.component";
import { CandidatesWorkflowComponent } from "../candidates-workflow/candidates-workflow.component";
import { OpenRolesComponent } from "../open-roles/open-roles.component";
@Component({
  selector: "role-detail",
  templateUrl: "./role-detail.component.html",
  styleUrls: ["./role-detail.component.css"],
})
export class RoleDetailComponent implements OnInit {
  @Input() role;
  @Input() clients;
  @Input() fromOpenRoles;
  @Output() cloneRole = new EventEmitter<any>();
  @Output() updateRoles = new EventEmitter<boolean>();

  sourcer_options: any = [];
  edit: boolean = false;
  detail_view: boolean = true;
  recommendation_view: boolean = false;

  hide_history: boolean = false;
  sortType: string = "last_update";
  sortReverse: boolean = true;

  user_name_by_id: any = {};
  work_permission_name_by_id = {};

  constructor(private talentService: TalentService,
     public dialog: MatDialog,
    @Optional() private dialogRef: MatDialogRef<OpenRolesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) data,
    ) {}

  ngOnInit() {
    this.talentService.getYodaweUsers().subscribe((result) => {
      this.sourcer_options = result;
      for (let u of result) {
        this.user_name_by_id[u["id"]] = u["name"];
      }
    });

    this.talentService.getDisplayYesNo().subscribe((result) => {
      for (let wp_info of result) {
        this.work_permission_name_by_id[wp_info["id"]] = wp_info["name"];
      }
    });
  }
  editRole() {
    this.edit = true;
    this.detail_view = false;
  }
  editRecommendations() {
    this.recommendation_view = true;
    this.detail_view = false;
  }
  cloneRoleClick() {
    this.cloneRole.emit(this.role);
  }
  handleEditFinish(save) {
    this.edit = false;
    this.detail_view = true;
    if (save) {
      this.updateRoles.emit();
    }
  }
  handleRecommendationEditFinish(save) {
    this.recommendation_view = false;
    this.detail_view = true;
  }
  findRecruiter(role) {
    let res = "";
    for (let rec of role.recruiters) {
      if (res != "") {
        res += ", ";
      }
      res += this.user_name_by_id[rec];
    }
    return res;
  }

  findSourcer(role) {
    let res = "";
    for (let rec of role.sourcers) {
      if (res != "") {
        res += ", ";
      }
      res += this.user_name_by_id[rec];
    }
    return res;
  }
  findManager(role) {
    let found = this.sourcer_options.find((option) => {
      return option.id === role.account_manager;
    });
    if (found) {
      return found.name;
    } else {
      return "";
    }
  }

  findWorkPermission(role) {
    let res = "";
    for (let wp of role.work_permission) {
      if (res != "") {
        res += ", ";
      }
      res += this.work_permission_name_by_id[wp];
    }
    return res;
  }

  showRoleFeedbackHistory(role_id): void {
    console.log("id",role_id)
    const dialogRef = this.dialog.open(CandidatesWorkflowComponent, {
      width: "1000px",
      height: "700px",
      data: { role_id: role_id,detail_view:"true/false",feedbackHistory:true},
    });
  }

  async createDocTemplate() {
    let html_string = "";
    html_string += `<h3>Job Title: ${this.role.title}</h3>`;
    html_string += `<h3>Client Name: ${this.role.client}</h3>`;
    html_string += `<h3>Open Date: ${this.role.open_date}</h3><br>`;
    html_string += `<h3>[SEQ Technology Description]:</h3> <p>SEQ Technology is an IT Services firm that provides staff augmentation, project and permanent placement
    opportunities for qualified candidates at prominent clients throughout North America. Owing to
    relationships that have been cultivated through multiple decades in the technology industry, SEQ operates
    offices in New York, Washington, D.C. and Toronto and services clients in the Financial Services, Technology,
    Healthcare and Federal Services sectors. Despite being a growing, multinational company, SEQ is deeply
    committed to fostering a warm and welcoming environment for all that work with us, contributing to our
    reputation as being one of the best places to work everywhere we operate. As evidenced by our name, SEQ
    is committed to Service, Execution and Quality, both in regards to client service and consultant care. We
    look forward to you joining our team! You can discover more about SEQ at https://seqtechnology.com</p><br>`;
    html_string += `<h3">[Job Summary (Client Description)]:</h3> ${this.role.client_description
      .split("<br>")
      .join("")}<br>`;
    html_string += `<h3 >[Public Job Description]:</h3> ${this.role.description
      .split("<br>")
      .join("")}<br>`;
    if (this.role.new_york_disclosure != "") {
      html_string += `<h3 >[Disclosure]:</h3> ${this.role.new_york_disclosure
        .split("<br>")
        .join("")}<br>`;
    }
    html_string += `<h3 >[EEO Disclosure]:</h3> <p>SEQ Technology LLC provides equal employment opportunities to all employees and applicants for
    employment and prohibits discrimination and harassment of any type without regard to race, color,
    religion, age, sex, national origin, disability status, genetics, protected veteran status, sexual orientation,
    gender identity or expression, or any other characteristic protected by federal, state or local laws.<br/>
    This policy applies to all terms and conditions of employment, including recruiting, hiring, placement,
    promotion, termination, layoff, recall, transfer, leaves of absence, compensation and training.</p><br>`;
    html_string = html_string
      .split("color: red")
      .join("color : rgda(191, 15, 15)");
    html_string = html_string
      .split("color: blue")
      .join("color : rgda(42, 96, 184)");
    html_string = html_string.split(", 0.9").join("");
    let filename = `SEQ Job-${this.role.client}-${this.role.title}-${this.role.open_date}`;
    filename = filename.split("/").join("-");
    let data = { html_string: html_string, filename: filename };
    this.talentService.DownloadPDFFromHTML(data).subscribe((res) => {
      const file = new Blob([res], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      saveAs(file, filename);
    });
  }

  templatePreview() {
    const dialogRef = this.dialog.open(RoleTemplateGeneratorComponent, {
      width: "1000px",
      height: "700px",
      data: {
        title: this.role.title,
        client: this.role.client,
        open_date: this.role.open_date,
        client_description: this.role.client_description,
        description: this.role.description,
        new_york_disclosure: this.role.new_york_disclosure,
      },
    });
  }
}
